import { data } from 'vue-echarts';
<template>
    <div class="container">
        <!-- <b-card>
            <b-row>
                <b-col cols="4" class="mt-1">
                    <label for="minimum_withdraw_coins">Minimum withdraw coins</label>
                    <b-form-input id="minimum_withdraw_coins" v-model="form.minimum_withdraw_coins" size="sm" />
                </b-col>
                <b-col cols="4" class="mt-1">
                    <label for="maximum_monthly_withdraw_coins">Maximum monthly withdraw coins</label>
                    <b-form-input id="maximum_monthly_withdraw_coins" v-model="form.maximum_monthly_withdraw_coins" size="sm" />
                </b-col>
                <b-col cols="4" class="mt-1">
                    <label for="coin_sell_price_percent">Coin sell price percent</label>
                    <b-form-input id="coin_sell_price_percent" v-model="form.coin_sell_price_percent" size="sm" />
                </b-col>
                <b-col cols="4" class="mt-1">
                    <label for="send_coin_commission_percent">Send coin commission percent</label>
                    <b-form-input id="send_coin_commission_percent" v-model="form.send_coin_commission_percent" size="sm" />
                </b-col>
                <b-col cols="4" class="mt-1">
                    <label for="editor">Last editor</label>
                    <h4></h4>
                    <b-badge variant="primary" class="badge-glow" id="editor">
                        {{ form.editor.name }}
                    </b-badge>
                </b-col>
                <b-col cols="12" class="text-center">
                    <b-button @click="submitHandler" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="danger" class="mt-1"> Submit </b-button>
                </b-col>
            </b-row>
        </b-card> -->
        <b-card v-if="withdrawItems">
            <b-row class="justify-content-center mb-4">
                <h4 class="font-weight-bolder">Withdraw Information</h4>
            </b-row>
            <b-row v-if="withdrawItems.image" class="justify-content-center my-2">
                <b-col cols="3" class="text-center">
                    Withdraw image: <b-img v-if="withdrawItems.image" fluid :src="withdrawItems.image" class="img"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="3">
                    amount: <b-badge variant="light-info">{{ withdrawItems.amount }}</b-badge>
                </b-col>
                <b-col cols="3">
                    amount_in_wct: <b-badge variant="light-info">{{ withdrawItems.amount_in_wct }}</b-badge>
                </b-col>
                <b-col cols="3">
                    currency_type: <b-badge variant="light-info">{{ withdrawItems.currency_type }}</b-badge>
                </b-col>
                <b-col cols="3">
                    exchange_rate: <b-badge variant="light-info">{{ withdrawItems.exchange_rate }}</b-badge>
                </b-col>
                <b-col cols="3" class="mt-1">
                    wallet_currency_type: <b-badge variant="light-info">{{ withdrawItems.wallet_currency_type }}</b-badge>
                </b-col>
                <b-col cols="3" class="mt-1" v-if="withdrawItems.refund_transaction">
                    refund_transaction: <b-badge variant="light-info">{{ withdrawItems.refund_transaction.transaction_status }}</b-badge>
                </b-col>
                <b-col cols="3" class="mt-1">
                    responsed_at: <b-badge variant="success" class="badge-glow">{{ withdrawItems.responsed_at }}</b-badge>
                </b-col>
                <b-col cols="3" class="mt-1" v-if="withdrawItems && withdrawItems.responser">
                    responser: <b-badge variant="info" class="badge-glow"> {{ withdrawItems.responser.name }} </b-badge>
                </b-col>
                <b-col cols="3" class="mt-1">
                    Status: <b-badge variant="primary"> {{ withdrawItems.withdraw_status }} </b-badge>
                </b-col>
            </b-row>
        </b-card>
        <b-card v-if="withdrawItems && withdrawItems.withdraw_response">
            <b-row class="justify-content-center mb-4">
                <h4 class="font-weight-bolder">Withdraw response</h4>
            </b-row>
            <b-row>
                <b-col cols="3">
                    amount : <b-badge variant="light-warning">{{ withdrawItems.withdraw_response.amount }}</b-badge>
                </b-col>
                <b-col cols="3">
                    date: <b-badge variant="light-warning">{{ withdrawItems.withdraw_response.date }}</b-badge>
                </b-col>
                <b-col cols="3">
                    description: <b-badge variant="light-warning">{{ withdrawItems.withdraw_response.description }}</b-badge>
                </b-col>
                <b-col cols="3">
                    reference_number: <b-badge variant="light-warning">{{ withdrawItems.withdraw_response.reference_number }}</b-badge>
                </b-col>
                <b-col cols="3" class="mt-1">
                    transferred_to: <b-badge variant="light-warning">{{ withdrawItems.withdraw_response.transferred_to }}</b-badge>
                </b-col>
            </b-row>
        </b-card>
        <b-card v-if="withdrawItems && withdrawItems.withdraw_source">
            <b-row class="justify-content-center mb-4">
                <h4 class="font-weight-bolder">Withdraw Source</h4>
            </b-row>
            <b-row>
                <b-col cols="3">
                    IBAN: <b-badge variant="light-info">{{ withdrawItems.withdraw_source.IBAN }}</b-badge>
                </b-col>
                <b-col cols="3">
                    account_number: <b-badge variant="light-info">{{ withdrawItems.withdraw_source.account_number }}</b-badge>
                </b-col>
                <b-col cols="3">
                    bank_name: <b-badge variant="light-info">{{ withdrawItems.withdraw_source.bank_name }}</b-badge>
                </b-col>
                <b-col cols="3">
                    email: <b-badge variant="light-info">{{ withdrawItems.withdraw_source.email }}</b-badge>
                </b-col>
                <b-col cols="3" class="mt-1">
                    name: <b-badge variant="light-info">{{ withdrawItems.withdraw_source.name }}</b-badge>
                </b-col>
                <b-col cols="3" class="mt-1">
                    surname: <b-badge variant="light-info">{{ withdrawItems.withdraw_source.surname }}</b-badge>
                </b-col>
            </b-row>
            <b-row class="justify-content-center mt-4">
                <b-button :disabled="withdrawItems.withdraw_status !== 'pending'" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="gradient-danger" @click="modalAllowed = true"> Response </b-button>
            </b-row>
        </b-card>
        <b-modal centered size="xl" title="Response" ok-only ok-title="cancel" v-model="modalAllowed">
            <b-row class="justify-content-center">
                <div class="col-4 text-center">
                    <label for="homeBannner"> Withdraw image </label>
                    <image-cropper name="homeBannner" class="col-12" v-model="fileForm" label="manager image" :url.sync="form.thumbnail" />
                    <!--/ upload button -->
                    <!-- </div> -->
                    <!-- </b-row> -->
                    <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                    <!-- </b-media-body> -->
                    <!-- </b-media> -->
                    <!--/ media -->
                </div>
            </b-row>
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <b-row class="mt-3">
                        <b-col cols="3">
                            <validation-provider name="City">
                                <b-form-group label="Withdraw status" label-for="withdraw_status"> <v-select v-model="form.withdraw_status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="withdrawStatusOptions" :clearable="false" /> </b-form-group
                            ></validation-provider>
                        </b-col>
                        <b-col cols="3">
                            <validation-provider name="Amount">
                                <b-form-group label="Amount" label-for="Amount">
                                    <b-form-input id="amount" v-model="form.amount" :disabled="form.withdraw_status == 'rejected' ? true : false" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="3">
                            <validation-provider name="Transferred to">
                                <b-form-group label="Transferred to" label-for="transferred_to">
                                    <b-form-input id="transferred_to" v-model="form.transferred_to" :disabled="form.withdraw_status == 'rejected' ? true : false" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="3">
                            <validation-provider name="Reference_number">
                                <b-form-group label="Reference number" label-for="reference_number">
                                    <b-form-input id="reference_number" v-model="form.reference_number" :disabled="form.withdraw_status == 'rejected' ? true : false" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" sm="6" md="4">
                            <validation-provider name="start_time">
                                <b-form-group label="Start Time" label-for="start_time">
                                    <b-form-datepicker id="datepicker-placeholder" placeholder="Choose a Time" local="en" :initial-date="date" class="mx-1" v-model="start_time" :disabled="form.withdraw_status == 'rejected' ? true : false" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" sm="6" md="2" class="text-center">
                            <b-form-timepicker class="mt-2 text-center" v-model="time" show-seconds locale="en" :disabled="form.withdraw_status == 'rejected' ? true : false" />
                        </b-col>
                        <b-col cols="12">
                            <validation-provider name="Description">
                                <b-form-group label="Description" label-for="Description">
                                    <b-form-input id="description" v-model="form.description" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="" type="submit"> Submit </b-button>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import { BPopover, BBadge, BListGroup, BListGroupItem, BTable, BAvatar, BFormCheckbox, BFormSelect, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BOverlay, BPagination, BFormDatepicker, BFormTimepicker } from "bootstrap-vue";
import { ref, onBeforeUnmount, onMounted, watchEffect } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import UserRepository from "@/abstraction/repository/userRepository";
import useWalletList from "./useWalletList";
const userRepository = new UserRepository();
import WalletRepository from "@/abstraction/repository/walletRepository";
const walletRepository = new WalletRepository();
import useFile from "../file/useFile";
import { makeid } from "@/utils/math";
const RANDOM_TOKEN = makeid(50);
import FileRepository from "@/abstraction/repository/fileRepository";
export default {
    components: {
        BPopover,
        BBadge,
        BListGroup,
        BListGroupItem,
        BPagination,
        BTable,
        BAvatar,
        BOverlay,
        BTab,
        BFormInput,
        BFormCheckbox,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        BFormDatepicker,
        BFormTimepicker,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    setup(props, context) {
        const { getResponse, setConfig } = useWalletList();
        const form = ref({
            withdraw_status: "completed",
            file_batch_id: RANDOM_TOKEN,
        });
        const withdrawItems = ref();
        const modalAllowed = ref(false);
        const withdrawStatusOptions = ref(["completed", "rejected"]);
        const { storeFile, fileForm } = useFile(RANDOM_TOKEN, "main_image", "wallet_withdraws");
        const start_time = ref();
        const time = ref();
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        const date = ref(yyyy + "-" + mm + "-" + dd);
        getResponse(router.currentRoute.params.id).then((res) => {
            withdrawItems.value = res.data.data;
        });
        const onSubmit = async () => {
            if (form.value.thumbnail) {
                delete form.value.thumbnail;
            }
            try {
                const fileRepository = new FileRepository();
                if (fileForm.value.file) {
                    await fileRepository.store(fileForm.value);
                }
                form.value.date = `${start_time.value} ${time.value}`;
                await walletRepository.storeResponse(form.value, router.currentRoute.params.id);

                // router.push({ name: "apps-composer-list" });
            } catch (e) {
                return e;
            }
        };

        return { form, onSubmit, withdrawItems, modalAllowed, fileForm, withdrawStatusOptions, date, time, start_time };
    },
};
</script>

<style>
.img{
    width:300px;
    height:300px
}
</style>
