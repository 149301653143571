import axios from 'axios'
import url from '../url'
import {
    getArray, setQuery, getJson, setData,
} from '../resources/userResource'

export default class UserRepository {
    async index(data) {
        const params = setQuery(data)
        const response = await axios.get(url('indexUsers'), { params })
        if (response.status === 200) {
            return getArray(response.data)
        }
    }

    async show(userId) {
        const response = await axios.get(url('showUser', { user: userId }))
        if (response.status === 200) {
            return getJson(response.data.data)
        }
    }

    async store(data) {
        const json = setData(data)
        const response = await axios.post(url('storeUser'), json)
        if (response.status === 201) {
            return getJson(response.data)
        }
    }

    async update(userId, data) {
        const json = setData(data)
        const response = await axios.put(
            url('updateUser', { user: userId }),
            json,
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async delete(userId) {
        await axios.delete(url('destroyUser', { user: userId }))
    }

    async activate(userId) {
        await axios.post(url('activateUser', { user: userId }))
    }

    async changePassword(user, data) {
        return await axios.post(url('userChangePassword', { user }), data)
    }
}
