import { SetPagination, SetQueriesObject } from "@/utils/setQueriesObject";

export const getJson = (data) => ({
    id: data.id,
    name: data.name,
    bio: data.bio,
    name_ar: data.name_ar,
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    email_verified_at: data.email_verified_at,
    is_artist: data.is_artist,
    is_active: data.is_active,
    created_at: data.created_at,
    cell_number: data.cell_number,
    city_id: data.city_id,
    country_id: data.country_id,
    image: data.image,
    thumbnail: data.thumbnail,
    role: data.role,
    country_code: data.country_code,
    level: data.level,
    user_name: data.user_name,
    gender: data.gender,
    have_verify_tick: data.have_verify_tick,
    badges: data.badges,
});
export const getArray = ({ data, meta }) => {
    const pagination = SetPagination(meta);
    data = data.map((user) => getJson(user));
    return { data, pagination };
};
export const setData = (data) => ({
    first_name: data.first_name,
    last_name: data.last_name,
    user_name: data.user_name,
    gender: data.gender,
    cell_number: data.cell_number,
    country_code: data.country_code,
    email: data.email,
    is_artist: data.is_artist,
    file_batch_id: data.file_batch_id,
    country_id: data.country_id,
    city_id: data.city_id,
    level: data.level,
    have_verify_tick: data.have_verify_tick,
    is_admin: data.is_admin,
    badges: data.badges,
});
export const setQuery = (data) => SetQueriesObject(data);
