import axios from "axios";
import url from "../url";
import { getArray, setQuery, getJson, setData, setComingSoonData, getComingSoonArray, getComingSoonJson } from "../resources/notificationResource";

export default class WalletRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle;
    }
    async giveBonusToUser(data) {
        const response = await axios.post(url("giveBonusToUser"), data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async addCurrencyType(data) {
        const response = await axios.post(url("addCurrencyType"), data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async index(userId) {
        const response = await axios.get(url("usersWallet", { id: userId }));
        if (response.status === 200) {
            return response;
        }
    }
    async logDetails(logId) {
        const response = await axios.get(url("logDetail", { id: logId }));
        if (response.status === 200) {
            return response;
        }
    }
    async editCurrencyTypes(data) {
        const response = await axios.put(url("editCurrencyTypes", { id: data.id }), data);
        if (response.status === 200) {
            return response;
        }
    }
    async showCurrency(userId) {
        const response = await axios.get(url("showCurrency", { id: userId }));
        if (response.status === 200) {
            return response;
        }
    }
    async userTransactionList(data, userId) {
        const params = setQuery(data);
        const response = await axios.get(url("userTransactionList", { id: userId }), { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async adminLogList(data) {
        const params = setQuery(data);
        const response = await axios.get(url("adminLogList"), { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async allCurrencies() {
        const response = await axios.get(url("allCurrencies"));
        if (response.status === 200) {
            return response.data;
        }
    }
    async fetchTotalsData(userId) {
        const response = await axios.get(url("totalsPaymentsData"));
        if (response.status === 200) {
            return response;
        }
    }
    async fetchTotalPaymentList(data) {
        const params = setQuery(data);
        const response = await axios.get(url("totalPaymentList"), { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async fetchTotalTransactionList(data) {
        const params = setQuery(data);
        const response = await axios.get(url("totalTransactionList"), { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async fetchWhitdrawRequests(data) {
        const params = setQuery(data);
        const response = await axios.get(url("whitdrawRequests"), { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async totalPayments(totalPaymentId) {
        const response = await axios.get(url("totalPayments", { id: totalPaymentId }));
        if (response.status === 200) {
            return response;
        }
    }
    async totalTransactions(totalTransactionId) {
        const response = await axios.get(url("totalTransactions", { id: totalTransactionId }));
        if (response.status === 200) {
            return response;
        }
    }
    
    async sendMultipleBonuses(data) {
        const response = await axios.post(url("sendMultipleBonuses"), data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async getReferalCode() {
        const response = await axios.get(url("getReferalCode"));
        if (response.status === 200) {
            return response;
        }
    }
    async getConfigs() {
        const response = await axios.get(url("getConfigs"));
        if (response.status === 200) {
            return response;
        }
    }
    async setReferalCode(data) {
        const response = await axios.post(url("setReferalCode"), data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async storeResponse(data,id) {
        const response = await axios.post(url("storeResponse",{ id: id }), data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async getResponse(id) {
        const response = await axios.get(url("getResponse", { id: id }));
        if (response.status === 200) {
            return response;
        }
    }
    async setConfig(data) {
        const response = await axios.put(url("setConfig"), data);
        if (response.status === 201) {
            return response.data;
        }
    }
}
