import { SetPagination, SetQueriesObject } from "@/utils/setQueriesObject";

export const getJson = (data) => ({
    id: data.id,
    name: data.name,
    slidable_type: data.slidable_type,
    slidable_id: data.slide_detail !== null ? data.slide_detail.name : "",
    type_name: data.slide_detail !== null ? data.slide_detail.name : "",
    status: data.is_active,
    thumbnail: data.banner_thumbnail,
    image: data.image,
    user: data.user ?? {},
    link: data.link !== null ? data.link : "",
    click_count: data.click_count,
});
export const getComingSoonJson = (data) => ({
    id: data.id,
    name: data.name,
    coming_soonable_type: data.coming_soonable_type,
    coming_soonable_id: data.slide_detail !== null ? data.slide_detail.name : "",
    type_name: data.slide_detail !== null ? data.slide_detail.name : "",
    status: data.is_active,
    thumbnail: data.banner_thumbnail,
    image: data.image,
    user: data.user ?? {},
    link: data.link !== null ? data.link : "",
    click_count: data.click_count,
});
export const setComingSoonData = (data) => ({
    coming_soonable_type: data.coming_soonable_type,
    coming_soonable_id: data.coming_soonable_id,
    name: data.name,
    link: data.link,
    file_batch_id: data.file_batch_id,
});
export const getArray = ({ data, meta }) => {
    const pagination = SetPagination(meta);
    // data = data.map((playList) => getJson(playList));
    return { data, pagination };
};
export const setData = (data) => ({
    slidable_type: data.slidable_type,
    slidable_id: data.slidable_id,
    name: data.name,
    link: data.link,
    file_batch_id: data.file_batch_id,
});
export const setQuery = (data) => SetQueriesObject(data);
