import { ref } from '@vue/composition-api'

// Notification
import FileRepository from '@/abstraction/repository/fileRepository'

const repository = new FileRepository({ errorHandle: false })
export default function useFile(batchId, collectionName, modelName, modelId = null) {
    const fileForm =  ref({
        batch_id: batchId,
        collection_name: collectionName,
        model_name: modelName,
        model_id: modelId,
        file: null,
        crop: {},
    })

    const storeFile = async () => {
        try {
            return await repository.store(fileForm.value)
        } catch (e) {
            return false
        }
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        fileForm,
        storeFile,
    }
}
